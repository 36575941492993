<template>
<div id="tips-view-games-table" v-touch:swipe.left="SwipeLeft" v-touch:swipe.right="SwipeRight">
    <Loader v-if="loading" rows="20" cols="6"></Loader>
    <Alert v-else-if="games.length <= 0" variant="info" >Här fanns inga matcher att visa just nu!</Alert>
    <b-container fluid v-else>
        <b-row class="mt-2 mb-4">
            <b-col cols="12" md="4" class="mb-1">
                <UsersSelect :all="isSingleView" v-model="selected" :id="uid" title="Välj användare" :users="allusers"></UsersSelect>
            </b-col>
            <b-col cols="12" md="4" class="mb-1">
                <GameSelect :id="gid" v-on:select="GameSelect" showclear></GameSelect>
            </b-col>
            <b-col cols="12" md="4" class="mb-1">
                <b-select v-model="display" v-if="!isSingleView">
                    <b-select-option value="1">Matchdatum</b-select-option>
                    <b-select-option value="2">Grupp</b-select-option>
                </b-select>
                <b-select @change="FixPageScroll" v-model="usersort" v-if="isSingleView">
                    <b-select-option value="1">Efter namn</b-select-option>
                    <b-select-option value="2">Nuvarande placering</b-select-option>
                </b-select>
            </b-col>
        </b-row>
        <b-row no-gutters class="sticky-top bg-white tips-view-header" :class="scrolled ? 'sticky-top-shadow' : ''">
            <b-col cols="2" lg="2" class="text-left d-none d-lg-block">
                <label class="d-none d-md-inline">Match</label>
                <label class="d-inline d-md-none">M</label>
            </b-col>
            <b-col cols="2" lg="2" class="text-left d-none d-lg-block">
                Tid
            </b-col>
            <b-col cols="3" lg="2" class="text-left"><label class="d-none d-lg-inline">Hemma</label>
                <label class="d-inline d-lg-none">Hemma</label>
            </b-col>
            <b-col cols="3" lg="2" class="text-right"><label class="d-none d-lg-inline">Borta</label>
                <label class="d-inline d-lg-none">Borta</label>
            </b-col>
            <b-col cols="1" class="d-none d-sm-block">&nbsp;</b-col>
            <b-col cols="6" sm="5" lg="3" class="text-center">
                <b-row no-gutters>
                    <b-col cols="4">H</b-col>
                    <b-col cols="4">B</b-col>
                    <b-col cols="4">1X2</b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row no-gutters v-for="g in games" :key="g.id" class="tips-view-row align-items-center" :class="gameRowClasses(g)">
            <b-col cols="12" class="tips-sub-sticky border-bottom pb-1">
                <b-row no-gutters class="tips-view-game-row" @click="GameSelect(g)">
                    <b-col cols="6" lg="2" class="align-self-center">
                        <router-link class="text-dark" :class="{'text-muted' : !gameComplete(g)}" :to="'/visa/matcher/'+ g.id">
                            <label class="mr-1">{{g.group.name}}</label>
                            <label> #{{g.gamenr}}</label>
                        </router-link>
                    </b-col>
                    <b-col cols="6" lg="2" class="text-right text-lg-left align-self-center">
                        {{date(g)}}
                    </b-col>
                    <b-col v-if="g.hometeamid > 0" cols="3" lg="2" class="groups-team align-self-center" @click.stop.prevent="GoToTeam(g.hometeamid)">
                        <Team :team="team(g.hometeamid)"></Team>
                    </b-col>
                    <b-col cols="3" lg="2" v-else>TBD</b-col>
                    <b-col v-if="g.awayteamid > 0" cols="3" lg="2" class="text-right groups-team align-self-center" @click.stop.prevent="GoToTeam(g.awayteamid)">
                        <Team :team="team(g.awayteamid)" away></Team>
                    </b-col>
                    <b-col cols="3" lg="2" v-else>TBD</b-col>
                    <b-col cols="1" class="d-none d-sm-block">&nbsp;</b-col>
                    <b-col cols="6" sm="5" lg="3">
                        <b-row no-gutters class="tips-view-entry-score-holder">
                            <b-col cols="4" class="tips-view-entry-score">{{g.homescore}}</b-col>
                            <b-col cols="4" class="tips-view-entry-score">{{g.awayscore}}</b-col>
                            <b-col cols="4" class="tips-view-entry"><span v-html="bet(g.result)"></span></b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" v-for="u in users" :key="u.id" class="px-0 mx-0 border-bottom py-1 tips-view-user-row">
                <b-row no-gutters class="user-answer-row" :class="{'current-user-row' : currentUser.id == u.id}">
                    <b-col cols="6" lg="8" class="pl-3 py-1 groups-team align-self-center" :id="'viewtips_user_row_' + u.id" >
                        <b-img rounded="circle" class="tips-user-image" :src="avatar(u)" />{{u.username}}
                        <UserPopover :target="'viewtips_user_row_' + u.id" :user="{ userid: u.id, username: u.username}"></UserPopover>
                    </b-col>
                    <b-col cols="1" class="text-center d-none d-sm-block">&nbsp;</b-col>
                    <b-col cols="6" sm="5" lg="3">
                        <b-row no-gutters class="tips-view-entry-score-holder">
                            <b-col cols="4" class="tips-view-entry-score" :class="UserAnswerClass(g, u.id, 'homescore')">
                                <span v-html="getUserAnswer(g.id, u.id,'homescore')"></span>
                            </b-col>
                            <b-col cols="4" class="tips-view-entry-score" :class="UserAnswerClass(g, u.id, 'awayscore')">
                                <span v-html="getUserAnswer(g.id, u.id,'awayscore')"></span>
                            </b-col>
                            <b-col cols="4" class="tips-view-entry" :class="UserAnswerClass(g, u.id, 'bet')">
                                <span v-html="getUserAnswer(g.id, u.id,'bet')"></span>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
/* eslint-disable */
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");
const UsersSelect = () => import("@/components/miscellaneous/UsersSelect");
const GameSelect = () => import("@/components/tips/view/games/GameSelect");
const Team = () => import("@/components/team/Team");

export default {
    name: "ViewGamesTable",
    components: {
        Alert,
        Loader,
        UsersSelect,
        GameSelect,
        Team
    },
    data() {
        return {
            display: 1,
            scrolled: false,
            selected: [],
            gid: "",
            uid: "",
            gindex: false,
            loadscroll: false,
            usersort: "2",
        };
    },
    computed: {
        isSingleView() {
            if (this.gid && this.gid > 0)
                return true;
            return false;
        },
        loading() {
            return this.$store.getters["tipsg/gamesloading"] ||
                this.$store.getters["tournamentsg/tournamentusersloading"];
        },
        games() {
            var all = this.allgames;
            if (this.gid) {
                var g = false;
                for (var i = 0; i < all.length; i++) {
                    g = all[i];
                    if (g.id == this.gid) {
                        this.gindex = i;
                        return [g];
                    }
                }
            }
            return all;
        },
        allgames() {
            var data = this.$store.getters["tipsg/games"];
            if (data) {
                var all = data.games;
                var key = this.display == 1 ? "gamedate" : "groupid";
                all = this.$functions.sort(all, key);
                return all;
            }
            return [];
        },
        users() {
            let data = false;
            if (this.usersort == "1") {
                data = this.$store.getters["tournamentsg/tournamentusers"];

            } else if (this.usersort == "2") {
                // efter placering
                var susers = this.$store.getters["stats/stats"];
                var tusers = this.$store.getters["tournamentsg/tournamentusers"];

                if (susers) {
                    data = [];
                    for (var i = 0; i < susers.stats.length; i++) {
                        var suser = susers.stats[i];
                        var u = this.$functions.findById(tusers, "id", suser.userid);
                        data.push(u);
                    }
                }
            }

            if (data) {
                return data.filter((u) => {
                    return this.selected.includes(u);
                });
            }
            return [];
        },
        allusers() {
            return this.$store.getters["tournamentsg/tournamentusers"];
        },
        userAnswers() {
            var data = this.$store.getters["tipsg/games"];
            if (data) {
                return data.answers;
            }
            return [];
        },
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    methods: {
        avatar(user) {
            return this.$images.userAvatar(user.id, user.imgversion);
        },
        gameComplete(g) {
            return g.status == 1;
        },
        team(tid) {
            return this.$functions.getTeam(tid);
        },
        date(g) {
            return this.$date.TeamGroupDate(g.gamedate);
        },
        bet(result) {
            return result === "0" ? "X" : (result ? result : "&nbsp;");
        },
        UserAnswerClass(game, userid, result) {
            var ua = this.getUserAnswer(game.id, userid, result);
            if (ua == "&nbsp;" || ua == "" || !this.gameComplete(game)) {
                return "";
            } else if (result == "bet") {
                if (ua === this.bet(game.result))
                    return "true";
                return "false";
            } else {
                if (ua === game[result])
                    return "true";
                return "false";
            }
        },
        getUserAnswer(gameid, userid, result) {
            var answers = this.userAnswers;
            if (answers && answers[userid] && answers[userid][gameid]) {
                if (result == "bet") {
                    return this.bet(answers[userid][gameid][result]);
                }
                return answers[userid][gameid][result];
            }
            return "&nbsp;";
        },
        handleScroll() {
            this.scrolled = window.scrollY > 0;
        },        
        GameSelect(game) {
            var url = "/visa/matcher";
            if (game)
                url += "/" + game.id;

            this.$router.push(url);
        },
        checkPage() {
            var gid = this.$route.params.id;
            this.loadscroll = true;
            if (gid && gid != "alla") {
                this.gid = gid;
            } else {
                this.gid = "";
                this.gindex = false;
            }
            var uid = this.$route.params.uid;
            if (uid) {
               this.uid = uid;
            }
            else {
                  this.uid = "";
            }
        },
        SwipeLeft() {
            this.swipe(true); // vänster ökar
        },
        SwipeRight() {
            this.swipe(false); // höger backar
        },
        swipe(isupp) {
            if (this.gindex || this.gindex === 0) {
                if (isupp) {
                    this.gindex++;
                    if (this.gindex >= this.allgames.length)
                        this.gindex = false;
                } else {
                    this.gindex--;
                    if (this.gindex < 0)
                        this.gindex = false;
                }
            }

            if (this.gindex === false) {
                if (isupp)
                    this.gindex = 0;
                else
                    this.gindex = this.allgames.length - 1;
            }

            var g = this.allgames[this.gindex];
            this.gid = g.id;
            this.$router.push("/visa/matcher/" + g.id);
            //history.pushState({}, null, "/visa/matcher/" + g.id);
        },
        dateAsId(date) {
            return this.$date.dateAsId(date);
        },
        gameRowClasses(g) {
            var classes = this.dateAsId(g.gamedate);
            if (!this.gameComplete(g)) {
                classes += " text-muted tips-open";
            }
            return classes;
        },
        FixPageScroll() {
            if (this.isSingleView) {
                var userrow = document.getElementsByClassName("current-user-row");
                if (userrow.length > 0) {
                    this.$functions.scroll(userrow[0], {
                        center: true
                    });
                }
            } else {
                var dateid = this.dateAsId(this.$date.dateNowWithoutTime());
                var dateelements = document.getElementsByClassName(dateid);
                if (dateelements.length > 0) {
                    this.$functions.scroll(dateelements[0], {
                        offsett: 50
                    });
                } else {
                    // finns nästa omgångs?
                    var openelements = document.getElementsByClassName("tips-open");
                    if (openelements.length > 0) {
                        this.$functions.scroll(openelements[0], {
                            offsett: 50
                        });
                    }
                }
            }
        },
        GoToTeam(teamid) {
            var team = this.$functions.getTeam(teamid);
            var url = this.$url.teamurl(team);
            this.$router.push(url);
        },
    },
    watch: {
        $route(to, from) {
            if (to != from)
                this.checkPage();
        }
    },
    mounted() {
        this.loadscroll = true;
        this.checkPage();
        // check if store
    },
    updated: function () {
        this.$nextTick(function () {
            if (this.loadscroll) {
                setTimeout(this.FixPageScroll, 350);
                this.loadscroll = false;
            }
        });
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
};
</script>
